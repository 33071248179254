import { makeSign } from "./tools";

let time = Math.floor(new Date().getTime() / 1000);
let app_id = Math.ceil(Math.random() * 100000);
let sign_obj = {
  time: time,
  app_id: app_id,
};
let sign = makeSign(sign_obj);
sign_obj["sign"] = sign;

export default sign_obj;
